import React, { useContext, useState } from 'react';
import * as schemas from './schemas';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { context as userContext } from 'context/user';

export default function Intercom({ user, store }) {
  const { REACT_APP_INTERCOM_APP_ID } = process.env;
  const [subscription, setSubscription] = useState();
  const { selectedStore } = useContext(userContext);
  const { isOnChargify } = selectedStore || false;

  const subscriptionQuery = useQuery(schemas.SUBSCRIPTION, {
    fetchPolicy: 'network-only',
    variables: { storeId: store.id },
    skip: !store.id || isOnChargify,
    onCompleted: data => setSubscription(data?.subscription),
    onError: err => {
      return '';
    },
  });

  if (!subscriptionQuery.loading && user && store && window.Intercom) {
    const companyDetails = {
      plan: subscription?.countryPlan?.plan?.titleEn || 'NA',
      website: store.fullUrl,
      store_country: store.countryCode,
      store_currency: store.currency?.titleEn,
      monthly_spend: store.monthlyTotalSales,
      number_of_branches: store.numberOfBranches || 0,
      plan_duration: subscription?.serviceData?.interval || 'NA',
      active_subscription_value: subscription?.total || 'NA',
      activation_time: subscription?.goActiveAt || 'NA',
      subscription_expiry: subscription?.expiresAt || 'NA',
      subscription_status: subscription?.status || 'NA',
      subscription_close_date: !!subscription?.invoices?.length ? subscription.invoices[0].createdAt : 'NA',
      parent_company_name: 'NA',
    };

    const company = {
      name: store.titleEn,
      company_id: store.id,
      created_at: store.createdAt ? moment(store.createdAt).unix() : null,
      ...companyDetails,
    };

    window.Intercom('boot', {
      app_id: REACT_APP_INTERCOM_APP_ID,
      user_hash: user.intercomUserHash,
      user_id: user.id,
      name: user.name,
      email: user.email,
      phone: user.phoneNumber,
      created_at: user.createdAt ? moment(user.createdAt).unix() : null,
      company: company,
      user_role: !!user.roles?.length ? user.roles[0].role : 'NA',
      store: store.titleEn,
      store_id: store.id,
      store_front_url: store.fullUrl,
      ...companyDetails,
    });
  }
  return '';
}
