import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import PaidThrough from '../PaidThrough';

const OrderDetailsPayments = ({ paidThrough, lang }) => (
  <div className="flex flex-col">
    <div className={cx('flex w-full', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
      <span className="text-xs font-semibold text-black">
        <Text value={translations.PAYMENT_METHODS} />
      </span>
    </div>
    <div className={cx('flex w-full mt-3 pb-4', lang === 'ar' && 'flex-row-reverse')}>
      <span className="text-xs flex">
        <PaidThrough paidThrough={paidThrough} />
      </span>
    </div>
  </div>
);

OrderDetailsPayments.propTypes = {
  paidThrough: PropTypes.string.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
};

export default OrderDetailsPayments;
