export const INVOICE_STATUS = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  CANCELLED: 'cancelled',
};

export const INVOICE_TYPE = {
  SUBSCRIPTION_RENEWAL_INVOICE: 'SubscriptionRenewalInvoice',
  SUBSCRIPTION_SETUP_INVOICE: 'SubscriptionSetupInvoice',
  SUBSCRIPTION_DUE_PERIOD_INVOICE: 'SubscriptionDuePeriodInvoice',
  SUBSCRIPTION_EXTRA_MONTHS_INVOICE: 'SubscriptionExtraMonthsInvoice',
  SUBSCRIPTION_CHANGE_PLAN_INVOICE: 'SubscriptionChangePlanInvoice',
  SMS_PACKAGE_INVOICE: 'SmsPackageInvoice',
};

export const INVOICE_TYPE_HUMANIZE = {
  SMS_PACKAGE: 'Sms package',
  SETUP: 'Setup',
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  SETUP: 'setup',
  LATE_PAYMENT: 'late_payment',
  CANCELLED: 'cancelled',
};
