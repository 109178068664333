import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { AlertTrianelgeIcon } from '@zydalabs/zac-icons-react';
import { Typography } from '@zydalabs/zac-react';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { ReactComponent as ProductIcon } from 'assets/product-place-holder.svg';
import OrderDetailsCard from './OrderDetailsCard';

const OrderDetailsItems = ({ order, currency }) => {
  const { lang, direction } = useContext(localeContext);
  const { orderItems } = order || {};

  return (
    <div className="flex flex-col w-full border-t border-b border-gray-200 mt-1">
      {orderItems.map((item, i) => {
        const { quantity, imageUrl, variant, menuItem, totalPrice, properties, notes, id: menuItemId } = item || {};
        const variantTitles = { titleAr: variant.titleAr, titleEn: variant.titleEn };
        return (
          <div key={menuItemId} className={cx('flex flex-row w-full pt-4', lang === 'ar' && 'flex-row-reverse')}>
            <div className={cx('flex-2 w-12 h-12 mb-4', lang === 'ar' ? 'ml-6' : 'mr-6')}>
              {imageUrl ? (
                <img src={imageUrl} className="w-full border rounded-lg h-full" alt="menu-item" />
              ) : (
                <ProductIcon />
              )}
            </div>
            <div className={cx('flex-1 flex flex-row border-gray-300', i < orderItems.length - 1 && 'border-b')}>
              <div className={cx('flex-1 flex', lang === 'ar' && 'flex-row-reverse')}>
                <div className="flex-1 flex-col">
                  <div className="flex flex-row justify-between">
                    <div
                      className={cx(
                        'flex-1 flex flex-row',
                        lang === 'ar' && 'flex-row-reverse',
                        'justify-between pb-1',
                      )}
                    >
                      <div className="flex flex-col">
                        <span className="text-sm font-semibold" style={{ direction }}>
                          <span
                            className={cx('text-sm font-semibold', lang === 'ar' ? 'flex-row-reverse ml-5' : 'mr-5')}
                            style={{ direction }}
                          >
                            {quantity} X
                          </span>
                          <Text value={menuItem} className="inline" />
                          {variant && (
                            <>
                              <span> - </span>
                              <Text value={variantTitles} className="inline" />
                              {variant.barCode && (
                                <span className={cx(lang === 'ar' ? 'mr-1' : 'ml-1')}>({variant.barCode})</span>
                              )}
                            </>
                          )}
                        </span>
                      </div>
                      <span style={{ direction }} className="text-sm font-semibold whitespace-nowrap">
                        <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
                        {totalPrice.toFixed(currency.decimals)}
                      </span>
                    </div>
                  </div>
                  {notes && notes.replace(/\s/g, '').length !== 0 && (
                    <div
                      style={{ direction }}
                      className="w-full flex items-center border border-yellow-200 whitespace-nowrap mt-2 mb-3 py-2 px-4 bg-yellow-50"
                    >
                      <AlertTrianelgeIcon width="20px" hanging="20px" color="#edb600" />
                      <div className={cx(lang === 'ar' ? 'mr-3' : 'ml-3', 'flex items-center')}>
                        <Text
                          value={translations.ORDER_NOTE}
                          className={cx(lang === 'ar' ? 'ml-3' : 'mr-3', 'font-semibold text-sm')}
                        />
                        <Typography variant="body14">{notes}</Typography>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col">
                    {properties.length > 0 &&
                      properties.map(property => {
                        const propertyTitles = { titleAr: property.titleAr, titleEn: property.titleEn };
                        return property.propertyValues.map(propertyValue => {
                          const propertyValueNames = { titleAr: propertyValue.titleAr, titleEn: propertyValue.titleEn };
                          return (
                            <OrderDetailsCard
                              title={propertyTitles}
                              quantity={propertyValue.quantity * quantity}
                              itemQuantity={quantity}
                              optionName={propertyValueNames}
                              price={propertyValue.price}
                              currency={currency}
                              numberOfEach={propertyValue.quantity}
                              lang={lang}
                              direction={direction}
                            />
                          );
                        });
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

OrderDetailsItems.propTypes = {
  order: PropTypes.shape({
    orderItems: PropTypes.arrayOf({
      quantity: PropTypes.number,
      imageUrl: PropTypes.string,
      variant: PropTypes.shape({
        barCode: PropTypes.string,
        discountEnabled: PropTypes.bool.isRequired,
        discountedPrice: PropTypes.number,
        externalId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        prepTime: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        propertySections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
        sku: PropTypes.string,
        titleAr: PropTypes.string.isRequired,
        titleEn: PropTypes.string.isRequired,
      }),
      menuItem: PropTypes.shape({ titleEn: PropTypes.string, titleAr: PropTypes.string }),
      totalPrice: PropTypes.number,
      propertyValues: PropTypes.shape({ quantity: PropTypes.number, price: PropTypes.number, id: PropTypes.string }),
      notes: PropTypes.string,
    }),
  }),
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
};
export default OrderDetailsItems;
