import React from 'react';
import PropTypes from 'prop-types';

import fetchStoreBranches from 'common/utils/dataBranches';
import { DropDown } from 'components/kit';
import * as translations from 'constants/translations';
import { Text } from 'components/service';

const BranchesDropDown = ({ branches, currentOption, onSelect, lang, isMobile }) => {
  const isSingleBranch = branches && branches.length === 1;
  const branchInDropDown = fetchStoreBranches(branches, isSingleBranch);

  return (
    <DropDown
      float={lang === 'ar' ? 'right' : 'left'}
      label={<Text value={isSingleBranch ? translations.BRANCH : translations.BUSINESS_LOCATION} />}
      optionSelected={currentOption}
      onSelect={onSelect}
      data={branchInDropDown}
      icon="keyboard_arrow_down"
      position={lang === 'ar' ? 'right' : 'left'}
      scrollable
      testId_openDropDown="select-business-location"
      testId_selectedData="business-location-name"
      testId_listData="business-location-list"
      wFull={isMobile}
      isDisabled={isSingleBranch}
      containerStyle={{
        backgroundColor: '#f3f4f6',
      }}
    />
  );
};

BranchesDropDown.propTypes = {
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentOption: PropTypes.string,
  onSelect: PropTypes.func,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  isMobile: PropTypes.bool,
};

export default BranchesDropDown;
