import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import 'styled-components/macro';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Link } from 'components/service';
import * as paths from 'paths.js';
import * as translations from 'constants/translations';
import { SUBSCRIPTION_STATUS } from 'constants/billing';
import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';
import { ReactComponent as WarningTriangle } from 'assets/alert-circle.svg';
import { useMobile } from 'hooks/index';
import subs from '../Details/billingSchema';

const RenewTopBar = () => {
  const isMobile = useMobile();
  const { direction, translate } = useContext(localeContext);
  const { user, hasRole, subscriptionStatusSetting, selectedStore } = useContext(userContext);
  const [storeId, setStoreId] = useState();
  const selectedStoreId = selectedStore?.id || 0;
  const { isOnChargify } = selectedStore || false;

  useEffect(() => {
    setStoreId(selectedStoreId);
  }, [selectedStoreId]);

  const subscriptionQueryResponse = useQuery(subs, {
    fetchPolicy: 'network-only',
    skip: !storeId || isOnChargify,
    variables: {
      storeId: storeId,
    },
    onCompleted: data => subscriptionStatusSetting && subscriptionStatusSetting(data.subscription?.status),
    onError: error => console.log(error.graphQLErrors),
  });

  const { subscription } = subscriptionQueryResponse?.data || { subscription: { status: '', cancelReason: '' } };
  const { status: subscriptionStatus, cancelReason: subscriptionCancelReason } = subscription;

  if (
    subscriptionQueryResponse.loading ||
    subscriptionQueryResponse.error ||
    !subscriptionStatus ||
    subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ||
    subscriptionStatus === SUBSCRIPTION_STATUS.SETUP ||
    !user ||
    !hasRole
  )
    return '';

  const isLateOrCancelled =
    subscriptionStatus === SUBSCRIPTION_STATUS.LATE_PAYMENT || subscriptionStatus === SUBSCRIPTION_STATUS.CANCELLED;

  const shouldShowAlert = hasRole('owner') || hasRole('ops_manager');

  return (
    <div>
      <div>
        <div
          style={{ direction }}
          className={cx(
            'z-50 py-3 pr-5 pl-4 flex justify-center items-center leading-squeeze text-sm text-left',
            isLateOrCancelled ? 'bg-zyda-red-500 text-white' : 'bg-orange-150 text-black',
          )}
        >
          {shouldShowAlert ? (
            <AlertCircle
              className={cx(
                'mx-3',
                isMobile ? 'w-16 h-16' : 'w-6 h-6',
                isLateOrCancelled ? 'fill-white' : 'fill-black',
              )}
            />
          ) : (
            <WarningTriangle
              className={cx(
                'mx-3',
                isMobile ? 'w-16 h-16' : 'w-8 h-8',
                isLateOrCancelled ? 'fill-white' : 'fill-black',
              )}
            />
          )}
          {subscriptionStatus === SUBSCRIPTION_STATUS.CANCELLED ? (
            subscriptionCancelReason !== SUBSCRIPTION_STATUS.LATE_PAYMENT ? (
              <p>
                {translate(translations.REQUEST_CANCELLED_SUBSCRIPTION)}
                &nbsp;
                <span className="font-semibold">{translate(translations.REQUEST_CANCELLED_SUBSCRIPTION_HINT)}</span>
              </p>
            ) : (
              <p>
                {translate(translations.CANCELLED_SUBSCRIPTION)}
                &nbsp;
                <span className="font-semibold">{translate(translations.CANCELLED_SUBSCRIPTION_HINT)}</span>
              </p>
            )
          ) : subscriptionStatus === SUBSCRIPTION_STATUS.LATE_PAYMENT ? (
            shouldShowAlert ? (
              <p>
                ({translate(translations.OWNER_LATE_SUBSCRIPTION)}&nbsp;
                <Link to={paths.invoices}>
                  <span className="font-bold underline">{translate(translations.OWNER_LATE_SUBSCRIPTION_LINK)}</span>
                </Link>
                {translate(translations.OWNER_LATE_SUBSCRIPTION_LINK_AFTER)})
              </p>
            ) : (
              <p>
                {translate(translations.OPERATOR_LATE_SUBSCRIPTION)}&nbsp;
                <span className="font-semibold">
                  {translate(translations.OPERATOR_LATE_SUBSCRIPTION_LINK)}
                  &nbsp;
                </span>
                {translate(translations.OPERATOR_LATE_SUBSCRIPTION_LINK_AFTER)}
              </p>
            )
          ) : shouldShowAlert ? (
            <p>
              {translate(translations.OWNER_EXPIRED_SUBSCRIPTION)}&nbsp;
              <Link to={paths.renew}>
                <span className="font-bold underline">{translate(translations.OWNER_EXPIRED_SUBSCRIPTION_LINK)}</span>
              </Link>
            </p>
          ) : (
            <p>
              {translate(translations.OPERATOR_EXPIRED_SUBSCRIPTION)}
              &nbsp;
              <span className="font-semibold">{translate(translations.OPERATOR_EXPIRED_SUBSCRIPTION_LINK)}</span>
              &nbsp;
              {translate(translations.OPERATOR_EXPIRED_SUBSCRIPTION_AFTER_LINK)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenewTopBar;
