import React from 'react';
import PropTypes from 'prop-types';

import { ORDER_STATUS } from 'constants/order';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Tag } from '@zydalabs/zac-react';

const StatusOrder = ({ status }) => {
  switch (status) {
    case ORDER_STATUS.ACCEPTED:
      return (
        <Tag
          color="blue"
          inner={{
            text: <Text value={translations.ACCEPTED_ORDERS} />,
          }}
          size="small"
        />
      );
    case ORDER_STATUS.CANCELED:
      return (
        <Tag
          color="red"
          inner={{
            text: <Text value={translations.CANCELED_ORDERS} />,
          }}
          size="small"
        />
      );
    case ORDER_STATUS.DELIVERED:
    case ORDER_STATUS.FULFILLED:
      return (
        <Tag
          color="success"
          inner={{
            text: <Text value={translations.COMPLETED_ORDERS} />,
          }}
          size="small"
        />
      );
    case ORDER_STATUS.DISPATCHED:
      return (
        <Tag
          color="blue"
          inner={{
            text: <Text value={translations.DISPATCHED_ORDERS} />,
          }}
          size="small"
        />
      );
    case ORDER_STATUS.SUBMITTED:
      return (
        <Tag
          color="orange"
          inner={{
            text: <Text value={translations.PENDING_ORDERS} />,
          }}
          size="small"
        />
      );

    case ORDER_STATUS.READY:
      return (
        <Tag
          color="blue"
          inner={{
            text: <Text value={translations.READY_ORDERS} />,
          }}
          size="small"
        />
      );
    default:
      return '';
  }
};

StatusOrder.propTypes = {
  status: PropTypes.oneOf([
    ORDER_STATUS.READY,
    ORDER_STATUS.DISPATCHED,
    ORDER_STATUS.DELIVERED,
    ORDER_STATUS.ACCEPTED,
    ORDER_STATUS.CANCELED,
    ORDER_STATUS.FULFILLED,
    ORDER_STATUS.SUBMITTED,
  ]),
};
export default StatusOrder;
